import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthenticationWebservice } from '@webservices/authentication.webservice';
import { Store } from '@ngxs/store';
import { AuthenticationState } from '@stores/authentication/authentication.state';
import { catchError, filter, first, switchMap, tap } from 'rxjs/operators';
import {
  RefreshTokenCreation,
  RefreshTokenResponse,
} from '@models/authentication.interface';
import {
  Logout,
  UpdateAccessToken,
} from '@stores/authentication/authentication.action';
import { RETURN_URL_KEY } from '@core/guards/is-logged.guard';
import { Router } from '@angular/router';
import { ToastService } from '@mychrono/ui-components';

/**
 * JWT Interceptor with token refresh mechanism
 */
@Injectable()
export class HttpAuthenticationInterceptor implements HttpInterceptor {
  private refreshingToken = false;
  private refreshTokenSubject = new BehaviorSubject<any>(null);

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly toastService: ToastService,
    private readonly authWebservice: AuthenticationWebservice
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authReq = request;
    const accessToken = this.store.selectSnapshot(
      AuthenticationState.accessToken
    );

    if (accessToken) {
      authReq = this.setTokenHeader(request, accessToken);
    }

    return next.handle(authReq).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          !authReq.url.includes('/authentication') &&
          !authReq.url.includes('/refresh-token') &&
          error.status === 401
        ) {
          return this.handle401(authReq, next);
        }

        if (
          error instanceof HttpErrorResponse &&
          error.error.name === 'InvalidPermission'
        ) {
          this.toastService.show({
            content: "Vous n'êtes pas autorisé à effectuer cette action.",
            header: 'Permission insuffisante',
            icon: 'triangle-exclamation',
            class: 'danger',
          });
        }

        return throwError(error);
      })
    );
  }

  /** Process a token refresh request and replay the original request
   * with new access token
   */
  private handle401(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.refreshingToken) {
      this.refreshingToken = true;
      this.refreshTokenSubject.next(null);
      const payload: RefreshTokenCreation = {
        _id: this.store.selectSnapshot(AuthenticationState.userId) as string,
        refreshToken: this.store.selectSnapshot(
          AuthenticationState.refreshToken
        ) as string,
      };
      if (payload.refreshToken && payload._id) {
        return this.authWebservice.refreshAccessToken(payload).pipe(
          catchError((error) => {
            this.refreshingToken = false;
            localStorage.setItem(RETURN_URL_KEY, this.router.url);
            this.store.dispatch(new Logout());
            return throwError(error);
          }),
          tap((token) => this.store.dispatch(new UpdateAccessToken(token))),
          switchMap((response: RefreshTokenResponse) => {
            this.refreshTokenSubject.next(response.accessToken);
            this.refreshingToken = false;
            return next
              .handle(this.setTokenHeader(request, response.accessToken))
              .pipe(
                catchError((e) => {
                  return throwError(e);
                })
              );
          })
        );
      } else {
        return this.store.dispatch(new Logout());
      }
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      first(),
      switchMap((token) => next.handle(this.setTokenHeader(request, token)))
    );
  }

  private setTokenHeader(
    request: HttpRequest<any>,
    token: string
  ): HttpRequest<any> {
    return request.clone({
      headers: request.headers.set('Authorization', 'Bearer ' + token),
    });
  }
}
